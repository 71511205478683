
import { defineComponent } from 'vue'
import { queryUserEmployeePrepaidRecordSum } from '@/api/advance.ts';

export default defineComponent({
  components: {},
  props: {
    cardId: {
      type: [String, Number],
      default: ''
    },
  },
  data() {
    return {
      detail: {} as any,
      listData: {
        allAmount: {
          name: '预支金额',
          value: 0
        },
        alrAmount: {
          name: '已领金额',
          value: 0
        },
        aitAmount: {
          name: '待领金额',
          value: 0
        },
        stopAmount: {
          name: '停发金额',
          value: 0
        },
        originAmount: {
          name: '实际预支',
          value: 0
        }
      } as any
    }
  },
  watch: {
    cardId: {
      handler(newval) {
        if (newval) {
          this.getData()
        }
      },
      immediate: true
    }
  },
  computed: {
    setDefault() {
      return (value: string) => {
        return value ?? '0'
      }
    }
  },
  methods: {
    getData() {
      queryUserEmployeePrepaidRecordSum({
        idCardNo: this.cardId
      }).then((res: any) => {
        this.detail = res
        for(const key in this.listData) {
          this.listData[key].value = res[key]
        }
      })
    }
  }
})
