
import { defineComponent } from 'vue'
import infoBill from './components/info-bill.vue'
import billDetail from './components/bill-detail.vue'
import billSum from './components/bill-sum.vue'
export default defineComponent({
  components: {
    infoBill,
    billDetail,
    billSum
  },
  data() {
    return {
      activeName: 'detail',
      serveCompanyId: '',
      startTime: '',
      endTime: ''
    }
  },
  mounted() {
    if (this.$route.query?.serveCompanyId) {
      this.serveCompanyId = atob((this as any).$route.query.serveCompanyId)
    }
    if (this.$route.query?.startTime) {
      this.startTime = atob((this as any).$route.query.startTime)
    }
    if (this.$route.query?.endTime) {
      this.endTime = atob((this as any).$route.query.endTime)
    }
  },
  methods: {
    handleClick(ev: string) {
      console.log(1111, ev)
    }
  }
})
