
import { defineComponent } from 'vue'
import { queryUserAdvanceList, exportUserAdvanceList } from '@/api/advance.ts';
import { statusMap, statusOption, auditStatusMap, colorMap } from '@/utils/map.ts';

import searchComponent from '@/components/search-component/index.vue'
import tableComponent from '@/components/table-component/index.vue';
import router from '@/router';

export default defineComponent({
    props: {
        cardId: {
            type: [String, Number],
            default: ''
        },
    },
    components: {
        searchComponent,
        tableComponent
    },
    data() {
        return {
            searchList: {
                status: {
                    name: '预支状态',
                    prop: 'status',
                    type: 'select',
                    placeholder: '请选择',
                    multiple: false,
                    value: '',
                    defaultValue: '',
                    options: statusOption,
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    }
                },
                paySalaryDate: {
                    name: '发薪时间',
                    prop: 'Date',
                    type: 'dateRange',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    value: [],
                    defaultValue: [],
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            buttonList: [
                // {
                //     name: '导出明细',
                //     buttonType: 'export',
                //     type: 'danger',
                //     fn: () => {
                //     }
                // }
            ] as any,

            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'serveCompanyName',
                    label: '所属企业'
                },
                {
                    prop: 'contactsUserName',
                    label: '负责人',
                },
                {
                    prop: 'advanceAmount',
                    label: '预支金额',
                    prefix: '￥',
                    defaultValue: '0'
                },
                {
                    prop: 'advanceApplyTime',
                    label: '申请时间',
                },
                {
                    prop: 'auditStatus',
                    label: '审批状态',
                    map: auditStatusMap,
                    colorMap: colorMap
                },
                {
                    prop: 'status',
                    label: '预支状态',
                    map: statusMap,
                    colorMap: colorMap
                },
                {
                    prop: 'applyRemark',
                    label: '预支备注',
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            selects: [] as any,

        }
    },
    watch: {
        cardId: {
            handler(newval) {
                if (newval) {
                    this.getData()
                }
            },
            immediate: true
        }
    },
    methods: {
        getData() {
            const query = this.createQuery()
            query.idCardNo = this.cardId
            queryUserAdvanceList(query).then((res: any) => {
                this.tableData = res.list
                this.pageInfo.total = res.totalCount
            })
        },
        handleSelectionChange(row: any) {
            this.selects = row.map((item: any) => item.id)
        },
        handleSizeChange(ev: number) {
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            this.pageInfo.pageNum = ev
            this.getData()
        },
        toDetail(row: any) {
            this.$router.push('/bill/advanceDetail')
        },
        createQuery(hasPage = true) {
            const query = {} as any
            if (hasPage) {
                query.pageNum = this.pageInfo.pageNum
                query.pageSize = this.pageInfo.pageSize
            }
            for (const key in this.searchList) {
                if (this.searchList[key].type === 'dateRange') {
                    query[`start${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[0] : ''
                    query[`end${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[1] : ''
                } else {
                    query[(this.searchList[key]).prop] = this.searchList[key].value
                }
            }
            return query
        },
        handleSearch() {
            this.pageInfo.pageNum = 1
            this.getData()
        },
        handleExport() {
            const query = this.createQuery(false)
            query.idCardNo = this.cardId
            query.ids = this.selects.join(',')
            exportUserAdvanceList(query).then(res => {
                window.location.href =
                    process.env.VUE_APP_BASE_URL +
                    '/common/download?delete=true&fileName=' +
                    res
                this.$message.success('导出成功！')
            })
        }
    }
})
